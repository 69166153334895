import { IdentityTypeNameDecorator } from '@nts/std';
import { MainIdentity } from './main.identity';
import { Expose } from '@nts/std/serialization';

@IdentityTypeNameDecorator('ItemIdentity')
export class ItemIdentity extends MainIdentity {

    private _itemCode: string;

    @Expose()
    get itemCode(): string {
        return this.getValue<string>(() => this._itemCode, 'itemCode');
    }
    set itemCode(value: string) {
        this.setValue<string>(() => { this._itemCode = value; }, value, 'itemCode');
    }
}
