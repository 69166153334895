import { StringPropertyViewModel } from "@nts/std";

export class MyStringPropertyViewModel extends StringPropertyViewModel {
    override get value(): string {
        return this.getValue();
    }
    override set value(updatedValue: string) {
        if (this.value !== updatedValue) {
            // quano faccio il sync model scatta onPropertyChanged partendo dal modello
            this.syncModel(updatedValue);
            if (this.model != null) {
                this.validate();
            } else if (this.parent != null && this.parent.validate) {
                this.parent.validate();
            }
        }
    }
}
