import { Component } from '@angular/core';
import { BaseAggregateComponent, DecodeTextBoxComponent, ExpandableComponent, LabelledTextBoxComponent } from '@nts/std';
import { MainViewModel } from '@nts/std/src/testing';
import { NgIf } from '@angular/common';
import { GridComponent } from '@nts/std';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    standalone: true,
    imports: [
        ExpandableComponent,
        LabelledTextBoxComponent,
        DecodeTextBoxComponent,
        GridComponent,
        NgIf
    ]
})
export class MainComponent extends BaseAggregateComponent<MainViewModel> {
}
