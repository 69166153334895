import { ItemViewModel } from './item.view-model';
import { Item } from '../../domain-models/main/item';
import { ItemIdentity } from '../../domain-models/main/item.identity';
import { CollectionViewModel, DomainModelCollection, ItemViewModelTypeDecorator } from '@nts/std';

@ItemViewModelTypeDecorator(ItemViewModel)
export class ItemCollectionViewModel extends CollectionViewModel<ItemViewModel, Item, ItemIdentity> {

    constructor() {
        super();

        // Typescript 2.1 BREAKING CHANGE
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, ItemCollectionViewModel.prototype);
    }

    async addForTesting() {
        await this.addNew();
    }

    getEntityCollectionForTesting(): DomainModelCollection<Item, ItemIdentity> {
        return this.collection;
    }

    removeForTesting(index: number) {
        this.remove(index);
    }

}
