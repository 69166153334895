import { CoreModel, IdentityTypeDecorator, ModelTypeNameDecorator } from '@nts/std';
import { MainIdentity } from './main.identity';
import { Expose } from '@nts/std/serialization';

@ModelTypeNameDecorator('AdditionalInfo')
@IdentityTypeDecorator(MainIdentity)
export class AdditionalInfo extends CoreModel<MainIdentity> {

    private _code: string;

    @Expose()
    get code(): string {
        return this.getValueForIdentityValue<string>(() => this._code, 'code');
    }
    set code(value: string) {
        this.setValueForIdentityValue<string>(() => { this._code = value; }, value, 'code');
    }

    private _additionalData;

    @Expose()
    get additionalData(): string {
        return this._additionalData;
    }
    set additionalData(value: string) {
        this.setValue<string>(() => {
            this._additionalData = value;
        }, value, 'additionalData');
    }
}
