import { BaseIdentity, IdentityTypeNameDecorator } from "@nts/std";
import { Expose } from '@nts/std/serialization';

@IdentityTypeNameDecorator('ExternalIdentity')
export class ExternalIdentity extends BaseIdentity {

    private _id: number;

    @Expose()
    get id(): number {
        return this.getValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'id');
    }
}
