import { ExternalIdentity } from '../../domain-models/main/external.identity';
import { External } from '../../domain-models/main/external';
import { AutoCompleteExternalOptions, ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from '@nts/std';

@IdentityTypeDecorator(ExternalIdentity)
@ExternalDomainModelTypeDecorator(External)
@ExternalDomainModelTypeNameDecorator('External')
export class ExternalExtViewModel extends ExternalViewModel<External, ExternalIdentity> {

    get id(): NumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    get description(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._description = value; }, this._description, 'description');
    }

    private _id: NumericPropertyViewModel;
    private _description: StringPropertyViewModel;

    override async postInit(): Promise<void> {
        this.autocomplete = true;
        this.autoCompleteOptions = new AutoCompleteExternalOptions();
        this.autoCompleteOptions.outputProperties = ['Id', 'Description'];
    }
}
