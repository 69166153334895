import { IdentityTypeDecorator, ModelTypeNameDecorator, CoreModel, NumberDecorator, StringDecorator } from '@nts/std';
import { RemoteExternalIdentity } from './remote-external.identity';
import { Expose } from '@nts/std/serialization';

@IdentityTypeDecorator(RemoteExternalIdentity)
@ModelTypeNameDecorator('RemoteExternal')
export class RemoteExternal extends CoreModel<RemoteExternalIdentity> {

    private _idX: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'idX' })
    get idX(): number {
        return this.getValueForIdentityValue<number>(() => this._idX, 'idX');
    }
    set idX(value: number) {
        this.setValueForIdentityValue<number>(() => { this._idX = value; }, value, 'idX');
    }

    private _descriptionX: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'descriptionX', minLength: 50 })
    get descriptionX(): string {
        return this.getValue<string>(() => this._descriptionX, 'descriptionX');
    }
    set descriptionX(value: string) {
        this.setValue<string>(() => { this._descriptionX = value; }, value, 'descriptionX');
    }
}
