import { Main } from '../../domain-models/main/main';
import { MyStringPropertyViewModel } from '../../property-view-models/my-string-property-view-model';
import { MyStringPropertyViewModelWithoutModel } from '../../property-view-models/my-string-property-view-model-without-model';
import { ExternalExtViewModel } from './external-ext-view-model';
import { MainIdentity } from '../../domain-models/main/main.identity';
import { ItemCollectionViewModel } from './item.collection-view-model';
import { RemoteExternalExtViewModel } from './remote-external-ext-view-model';
import { AdditionalInfoViewModel } from './additional-info.view-model';
import { CollectionViewModelTypeDecorator, ColumnInfoCollection, CustomPropertyViewModelDecorator, ExternalViewModelTypeDecorator, InternalViewModelTypeDecorator, MasterDetailRootViewModel, MetaDataUtils, StringDecorator, StringMetaData, StringPropertyViewModel } from '@nts/std';


export class MainViewModel extends MasterDetailRootViewModel<Main, MainIdentity> {

    // Dichiarazione standard di un property view model
    get code(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    // Custom property view model con domain model
    get code2(): MyStringPropertyViewModel {
        return this.getPropertyViewModel<MyStringPropertyViewModel>(
            (value) => { this._code2 = value; },
            this._code2,
            'code',
            MyStringPropertyViewModel,
            this.domainModelMetaData.strings);
    }

    // Custom setter/getter su code con domain model
    get code3(): StringPropertyViewModel {
        return this.getStringPropertyViewModel(
            (pvm) => { this._code3 = pvm; },
            this._code3,
            'code',
            () => this.domainModel.code,
            async (stringValue) => {
                stringValue = stringValue + 'a';
                this.domainModel.code = stringValue;
            }
        );
    }

    // Custom property view model su code senza domain model associato
    @CustomPropertyViewModelDecorator()
    @StringDecorator({ displayNameKey: 'code4' })
    get code4(): MyStringPropertyViewModelWithoutModel {
        if (this._code4 == null) {
            const init = this.createPVMInitializationInfo<StringMetaData>('code4', null, this);
            this._code4 = new MyStringPropertyViewModelWithoutModel(init);
        }
        return this._code4;
    }

    get data(): StringPropertyViewModel {
        return this.getStringPropertyViewModel(
            (value) => { this._data = value; }, this._data, 'data');
    }

    @InternalViewModelTypeDecorator(AdditionalInfoViewModel)
    info: AdditionalInfoViewModel;

    @ExternalViewModelTypeDecorator(ExternalExtViewModel)
    externalRef: ExternalExtViewModel;

    @ExternalViewModelTypeDecorator(ExternalExtViewModel)
    external2Ref: ExternalExtViewModel;

    @ExternalViewModelTypeDecorator(RemoteExternalExtViewModel)
    remoteExternal: RemoteExternalExtViewModel;

    @CollectionViewModelTypeDecorator(ItemCollectionViewModel)
    items: ItemCollectionViewModel;

    get itemsColumns(): ColumnInfoCollection {
        if (!this._itemsColumns) {
            const domainModelTypeName = 'Item';
            const baseTypesAvailableColumns = ['code', 'itemCode', 'itemDescription'];
            this._itemsColumns = this.getGridColumns(
                'items',
                domainModelTypeName,
                baseTypesAvailableColumns
            );
        }
        return this._itemsColumns;
    }

    private _code: StringPropertyViewModel;
    private _code2: MyStringPropertyViewModel;
    private _code3: StringPropertyViewModel;
    private _code4: MyStringPropertyViewModelWithoutModel;
    private _data: StringPropertyViewModel;
    private _itemsColumns: ColumnInfoCollection;

    override async postInit(): Promise<void> {

        this.code.isEnabled = false;
        // Custom setter
        const propertyMetaData = this.domainModelMetaData.strings.find(item => MetaDataUtils.toCamelCase(item.name) === 'code');
        const initInfo = this.createPVMInitializationInfo('code', propertyMetaData, this);
        this._code3 = new StringPropertyViewModel(initInfo);
        this._code3.customSetter = async (value: string) => { this.domainModel.code = value; };

    }
}
