import { Main } from '../../domain-models/main/main';
import { MainIdentity } from '../../domain-models/main/main.identity';
import { MainViewModel } from './main.view-model';
import { Injectable } from '@angular/core';
import { MainApiClient } from '../../api-clients/main/main.api-client';
import { AuthService, IdentityTypeDecorator, MasterDetailOrchestratorViewModel, ModalService, RootViewModelTypeDecorator, ToastMessageService } from '@nts/std';
import { OnlineService } from '@nts/std/utility';
import { EnvironmentConfiguration } from '@nts/std/environments';

@RootViewModelTypeDecorator(MainViewModel)
@IdentityTypeDecorator(MainIdentity)
@Injectable()
export class MainOrchestratorViewModel extends MasterDetailOrchestratorViewModel<MainViewModel, MainApiClient, Main, MainIdentity> {

    constructor(
        apiClient: MainApiClient,
        modalService: ModalService,
        env: EnvironmentConfiguration,
        authService: AuthService,
        toastMessageService: ToastMessageService,
        onlineService: OnlineService
    ) {
        super(apiClient, modalService, env, authService, toastMessageService, onlineService);

    }
}
