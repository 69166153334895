<div class="main-container" *ngIf="rootViewModel">
    <nts-expandable #expandableChild [title]="'Generale'">
        <nts-labelled-text-box [propertyViewModel]="rootViewModel.code">
        </nts-labelled-text-box>

        <nts-labelled-text-box [propertyViewModel]="rootViewModel.data">
        </nts-labelled-text-box>

        <nts-decode-text-box [externalPropertyViewModel]="rootViewModel.externalRef"
            [code]="rootViewModel.externalRef.id" [decodeDescription]="rootViewModel.externalRef.description">
        </nts-decode-text-box>

        <nts-decode-text-box [externalPropertyViewModel]="rootViewModel.external2Ref"
            [code]="rootViewModel.external2Ref.id" [decodeDescription]="rootViewModel.external2Ref.description">
        </nts-decode-text-box>

        <nts-decode-text-box [externalPropertyViewModel]="rootViewModel.remoteExternal"
            [code]="rootViewModel.remoteExternal.idX" [decodeDescription]="rootViewModel.remoteExternal.descriptionX">
        </nts-decode-text-box>
    </nts-expandable>

    <nts-expandable #expandableChild [disableAdditionalFields]="true" [title]="rootViewModel.info.metadataDescription">
        <nts-labelled-text-box [propertyViewModel]="rootViewModel.info.additionalData">
        </nts-labelled-text-box>
    </nts-expandable>

    <nts-expandable #expandableChild [disableAdditionalFields]="true" [title]="'Items'">
        <nts-grid [fullColumn]="true" [collectionViewModel]="rootViewModel.items"
            [columns]="rootViewModel.itemsColumns">
        </nts-grid>
    </nts-expandable>
</div>
