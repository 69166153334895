import { ItemIdentity } from '../../domain-models/main/item.identity';
import { Item } from '../../domain-models/main/item';
import { ItemViewModel as BaseItemViewMode, StringPropertyViewModel } from '@nts/std';


export class ItemViewModel extends BaseItemViewMode<Item, ItemIdentity> {

    private _code: StringPropertyViewModel;

    get code(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    private _itemCode: StringPropertyViewModel;

    get itemCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._itemCode = value; }, this._itemCode, 'itemCode');
    }

    private _itemDescription: StringPropertyViewModel;

    get itemDescription(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._itemDescription = value; }, this._itemDescription, 'itemDescription');
    }
}
