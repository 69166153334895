import { StringPropertyViewModel } from "@nts/std";

export class MyStringPropertyViewModelWithoutModel extends StringPropertyViewModel {

    override get value(): string {
        return this._value;
    }
    override set value(updatedValue: string) {
        this._value = updatedValue;
    }

    private _value = 'INITIAL_VALUE';    
}
