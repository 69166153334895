import { BaseIdentity, IdentityTypeNameDecorator } from "@nts/std";
import { Expose } from '@nts/std/serialization';

@IdentityTypeNameDecorator('MainIdentity')
export class MainIdentity extends BaseIdentity {

    private _code: string;

    @Expose()
    get code(): string {
        return this.getValue<string>(() => this._code, 'code');
    }
    set code(value: string) {
        this.setValue<string>(() => { this._code = value; }, value, 'code');
    }
}

