import { AutoCompleteExternalOptions, ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from '@nts/std';
import { RemoteExternal } from '../../domain-models/main/remote-external';
import { RemoteExternalIdentity } from '../../domain-models/main/remote-external.identity';

@IdentityTypeDecorator(RemoteExternalIdentity)
@ExternalDomainModelTypeDecorator(RemoteExternal)
@ExternalDomainModelTypeNameDecorator('RemoteExternal')
export class RemoteExternalExtViewModel extends ExternalViewModel<RemoteExternal, RemoteExternalIdentity> {

    get idX(): NumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._idX = value; }, this._idX, 'idX');
    }

    get descriptionX(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._descriptionX = value; }, this._descriptionX, 'descriptionX');
    }

    private _idX: NumericPropertyViewModel;
    private _descriptionX: StringPropertyViewModel;

    override async postInit(): Promise<void> {
        this.autocomplete = true;
        this.autoCompleteOptions = new AutoCompleteExternalOptions();
        this.autoCompleteOptions.outputProperties = ['IdX', 'DescriptionX'];
    }
}
