import { InternalViewModel, StringPropertyViewModel } from '@nts/std';
import { AdditionalInfo } from '../../domain-models/main/additional-info';
import { MainIdentity } from '../../domain-models/main/main.identity';

export class AdditionalInfoViewModel extends InternalViewModel<AdditionalInfo, MainIdentity> {

    private _code: StringPropertyViewModel;

    get code(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    private _additionalData: StringPropertyViewModel;

    get additionalData(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._additionalData = value; }, this._additionalData, 'additionalData');
    }
}
