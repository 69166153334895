import { External } from './external';
import { AdditionalInfo } from './additional-info';
import { ItemCollection } from './item.collection';
import { MainIdentity } from './main.identity';
import { RemoteExternal } from './remote-external';
import { Item } from './item';
import { ExternalDecorator, IdentityTypeDecorator, InternalDecorator, ModelTypeNameDecorator, NumberDecorator, OCCModel, StringDecorator, TextDecorator } from '@nts/std';
import { Type, Expose } from '@nts/std/serialization';

@IdentityTypeDecorator(MainIdentity)
@ModelTypeNameDecorator('Main')
export class Main extends OCCModel<MainIdentity> {

    private _code: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'code', maxLength: 50, isRequired: true })
    get code(): string {
        return this.getValueForIdentityValue<string>(() => this._code, 'code');
    }
    set code(value: string) {
        this.setValueForIdentityValue<string>(() => { this._code = value; }, value, 'code');
    }

    private _data: string;

    @Expose()
    @TextDecorator({ displayNameKey: 'data' })
    get data(): string {
        return this.getValue<string>(() => this._data, 'data');
    }
    set data(value: string) {
        this.setValue<string>(() => { this._data = value; }, value, 'data');
    }

    private _info: AdditionalInfo;

    @Expose()
    @InternalDecorator({ displayNameKey: 'Main_Info' })
    @Type(() => AdditionalInfo)
    get info(): AdditionalInfo {
        return this.getValueForInternal<AdditionalInfo>(
            (value) => {
                this._info = value;
            },
            this._info,
            'info',
            AdditionalInfo
        );
    }

    set info(value: AdditionalInfo) {
        this.setValueForInternal(() => {
            this._info = value;
        }, value, 'info');
    }

    private _externalId;

    @Expose()
    @NumberDecorator({ displayNameKey: 'externalId' })
    get externalId(): number {
        return this._externalId;
    }
    set externalId(value: number) {
        this.setValue<number>(() => {
            this._externalId = value;
        }, value, 'externalId');
    }

    private _externalRef: External;

    @ExternalDecorator({ displayNameKey: 'Main_ExternalRef', principalPName1: 'externalId', dependantPName1: 'id' })
    @Expose()
    @Type(() => External)
    get externalRef(): External {
        return this.getValueForExternal<External>(
            () => this._externalRef,
            'externalRef',
            External
        );
    }
    set externalRef(value: External) {
        this.setValueForExternal(() => {
            this._externalRef = value;
        }, value, 'externalRef');
    }

    private _externalId2;

    @Expose()
    @NumberDecorator({ displayNameKey: 'externalId2' })
    get externalId2(): number {
        return this._externalId2;
    }
    set externalId2(value: number) {
        this.setValue<number>(() => {
            this._externalId2 = value;
        }, value, 'externalId2');
    }

    private _external2Ref: External;

    @ExternalDecorator({ displayNameKey: 'Main_External2Ref', principalPName1: 'externalId2', dependantPName1: 'id' })
    @Expose()
    @Type(() => External)
    get external2Ref(): External {
        return this.getValueForExternal<External>(
            () => this._external2Ref,
            'external2Ref',
            External
        );
    }
    set external2Ref(value: External) {
        this.setValueForExternal(() => {
            this._external2Ref = value;
        }, value, 'external2Ref');
    }


    private _remoteExternalIdX;

    @Expose()
    @NumberDecorator({ displayNameKey: 'remoteExternalIdX' })
    get remoteExternalIdX(): number {
        return this._remoteExternalIdX;
    }
    set remoteExternalIdX(value: number) {
        this.setValue<number>(() => {
            this._remoteExternalIdX = value;
        }, value, 'remoteExternalIdX');
    }

    private _remoteExternal: RemoteExternal;

    @ExternalDecorator({ displayNameKey: 'Main_RemoteExternal', principalPName1: 'remoteExternal', dependantPName1: 'idX' })
    @Expose()
    @Type(() => RemoteExternal)
    get remoteExternal(): RemoteExternal {
        return this.getValueForExternal<RemoteExternal>(
            () => this._remoteExternal,
            'remoteExternal',
            RemoteExternal
        );
    }
    set remoteExternal(value: RemoteExternal) {
        this.setValueForExternal(() => {
            this._remoteExternal = value;
        }, value, 'remoteExternal');
    }

    private _items: ItemCollection;

    @Expose()
    @InternalDecorator({ displayNameKey: 'Main_Items', isCollection: true })
    @Type(() => ItemCollection)
    get items(): ItemCollection {
        return this.getValueForInternalCollection<ItemCollection, Item>(
            (value) => {
                this._items = value;
            },
            this._items,
            'items',
            ItemCollection
        );
    }

    set items(value: ItemCollection) {
        this.setValueForInternalCollection<ItemCollection, Item>(() => {
            this._items = value;
        }, value, 'items');
    }
}
