import { NgModule, Provider } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NtsAppModule, ComponentLocator, BaseRoutes } from '@nts/std';
import { MainApiClient, MainOrchestratorViewModel, MainViewModel } from '@nts/std/src/testing';
import { MainComponent } from './components/main/main.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(
            BaseRoutes.getBaseRoutes()
        )
    ],
    providers: []
})
export class MainModule extends NtsAppModule {

    constructor(
        componentLocator: ComponentLocator) {

        super(
            componentLocator
        );
    }

    static getRootProviders(): Provider[] {
        return [
            // TODO Tommy: sarebbe da includerli direttamente nei provider del modulo
            // purtroppo sembrerebbe che HttpClientModule sia stato già iniettato
            // da altri provider di questo sotto modulo e finchè non si riesce a levare
            // l'unico modo di far funzionare gli interceptor (o tutti quei provider che fanno uso dell'http)
            // è iniettare gli interceptor direttamente nell'app
            // {
            //     provide: HTTP_INTERCEPTORS,
            //     useClass: MockMainBackendInterceptor,
            //     multi: true
            // }
        ];
    }

    registerAll() {

        this.registerAllForDomainModelType(
            'Main',
            MainOrchestratorViewModel,
            MainViewModel,
            MainComponent,
            MainApiClient
        );
    }

}
