import { Injectable } from '@angular/core';
import { CrudApiClient, RootModelTypeDecorator, RootModelTypeNameDecorator } from '@nts/std';
import { Main } from '../../domain-models/main/main';
import { MainIdentity } from '../../domain-models/main/main.identity';

@Injectable()
@RootModelTypeNameDecorator('Main')
@RootModelTypeDecorator(Main)
export class MainApiClient extends CrudApiClient<Main, MainIdentity> {

}
