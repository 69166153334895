import { IdentityTypeNameDecorator, BaseIdentity } from "@nts/std";
import { Expose } from '@nts/std/serialization';

@IdentityTypeNameDecorator('RemoteExternalIdentity')
export class RemoteExternalIdentity extends BaseIdentity {

    private _id: number;

    @Expose()
    get id(): number {
        return this.getValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'id');
    }
}
