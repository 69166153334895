import { CoreModel, IdentityTypeDecorator, ModelTypeNameDecorator, NumberDecorator, StringDecorator } from '@nts/std';
import { ExternalIdentity } from './external.identity';
import { Expose } from '@nts/std/serialization';

@IdentityTypeDecorator(ExternalIdentity)
@ModelTypeNameDecorator('External')
export class External extends CoreModel<ExternalIdentity> {

    private _id: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'id' })
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    private _description: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'description', maxLength: 50 })
    get description(): string {
        return this.getValue<string>(() => this._description, 'description');
    }
    set description(value: string) {
        this.setValue<string>(() => { this._description = value; }, value, 'description');
    }
}
