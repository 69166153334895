import { CoreModel, IdentityTypeDecorator, ModelTypeNameDecorator, StringDecorator } from '@nts/std';
import { ItemIdentity } from './item.identity';
import { Expose } from '@nts/std/serialization';

@IdentityTypeDecorator(ItemIdentity)
@ModelTypeNameDecorator('Item')
export class Item extends CoreModel<ItemIdentity> {

    private _code: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'code', maxLength: 50, isRequired: true })
    get code(): string {
        return this.getValueForIdentityValue<string>(() => this._code, 'code');
    }
    set code(value: string) {
        this.setValueForIdentityValue<string>(() => { this._code = value; }, value, 'code');
    }

    private _itemCode: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'itemCode', maxLength: 50, isRequired: true })
    get itemCode(): string {
        return this.getValueForIdentityValue<string>(() => this._itemCode, 'itemCode');
    }
    set itemCode(value: string) {
        this.setValueForIdentityValue<string>(() => { this._itemCode = value; }, value, 'itemCode');
    }

    private _itemDescription: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'itemDescription', maxLength: 50 })
    get itemDescription(): string {
        return this.getValue<string>(() => this._itemDescription, 'itemDescription');
    }
    set itemDescription(value: string) {
        this.setValue<string>(() => { this._itemDescription = value; }, value, 'itemDescription');
    }
}
